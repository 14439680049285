import React from 'react'
import {
  Typography
} from '@material-ui/core'
import { getStatusMetadata } from 'src/common/NACommon'
import moment from 'moment-timezone'
import AciHighwayCargoApiUrls from "../../apiUrls/AciHighwayCargoApiUrls"
import { DateTimeFormatter, constants } from 'cng-web-lib'
import TemplateSelectDialog from 'src/components/aciacehighway/TemplateSelectDialog'
import { useHistory } from 'react-router-dom'
import { FileForUserGetPartyId } from 'src/common/FileForUserCommon'

function TemplateSelectDialogWithOptionACIHWYCargo(props) {
  const {
    cargoTemplateDialog,
    setCargoTemplateDialog,
    setConfirmDialog,
    showNotification,
    getTranslatedText,
    translatedTextsObject,
    setLicensePlateCount,
    setValue,
    rowActions,
    reset,
    trigger,
    isTable,
    location,
    setOnChangeCarrierCode,
    setRefreshCarrierCodeAndCCN
  } = props

  const {
    filter: { LIKE, EQUAL }
  } = constants

  const history = useHistory()

  function handleApplyCargoTemplate(template) {
    if (template) {
      const {
        cargoType,
        consolidated,
        carrierCode,
        ccn,
        firstExpectedPort,
        arrivalDateAndTime,
        arrivalDate,
        arrivalTime,
        arrivalDateAndTimeZone,
        paperRelease,
        inbondDestinationOfficeCode,
        warehouseCode,
        polCity,
        polCountryCode,
        polStateCode,
        poaCity,
        poaCountryCode,
        poaStateCode,
        adminName,
        adminContact,
        specialInstructions,
        portOfExit,
        acquittalNo,
        printDate,
        printRemarks,
        messageFunction,
        // amendmentCode,
        // status,
        tripNo,
        manifestId,
        // templateFlag,
        // templateName,
        version,
        cargoLoadedIn,
        cargoParties,
        cargoCommodity,
        cargoLoadedInTruck,
        cargoLoadedInTrailer,
        entryNo,
        // hazardousMaterial,
        partyId,
        modeOfSubmission,
        oriCreatedBy,
        oriUpdatedBy,
        oriSubmittedBy,
        apiAutoSubmit
      } = template

      var arrivalTimeSeparate = "23:59";
      var arrivalDateSeparate = moment().tz("Canada/Eastern").format('YYYY-MM-DD');
      cargoParties.forEach((cargo) => {
        cargo.id = null;
        cargo.createdBy = null;
        cargo.createdDate = null;
        cargo.updatedBy = null;
        cargo.updatedDate = null;
      });

      cargoCommodity.forEach((commodity) => {
        commodity.id = null;
        commodity.createdBy = null;
        commodity.createdDate = null;
        commodity.updatedBy = null;
        commodity.updatedDate = null;
        commodity.hazardousMaterial.forEach((hazardousMaterial) => {
          hazardousMaterial.id = null;
          hazardousMaterial.createdBy = null;
          hazardousMaterial.createdDate = null;
          hazardousMaterial.updatedBy = null;
          hazardousMaterial.updatedDate = null;
          });
      });

      if (isTable) {
        var templateJson = {
          "baseFilterDTO": null,
          "errorMessages": null,
          "customData": null,
          "id": null,
          "version": 0,
          "createdBy": null,
          "createdDate": null,
          "updatedBy": null,
          "updatedDate": null,
          "partyId": null,
          "cargoType": cargoType,
          "consolidated": consolidated,
          "carrierCode": carrierCode,
          "ccn": ccn,
          "arrivalDate": arrivalDateSeparate,
          "arrivalTime": arrivalTimeSeparate,
          "firstExpectedPort": firstExpectedPort,
          "arrivalDateAndTimeZone": arrivalDateAndTimeZone,
          "paperRelease":paperRelease,
          "inbondDestinationOfficeCode": inbondDestinationOfficeCode,
          "warehouseCode":warehouseCode,
          "polCity": polCity,
          "polCountryCode": polCountryCode,
          "polStateCode": polStateCode,
          "poaCity": poaCity,
          "poaCountryCode": poaCountryCode,
          "poaStateCode": poaStateCode,
          "adminName": adminName,
          "adminContact":adminContact,
          "specialInstructions": specialInstructions,
          "portOfExit": portOfExit,
          "acquittalNo": acquittalNo,
          "printDate": printDate,
          "printRemarks": printRemarks,
          "messageFunction": messageFunction,
          "amendmentCode": "",
          "status": "",
          "tripNo": "",
          "manifestId": null,
          "templateFlag": false,
          "templateName": "",
          "cargoParties": cargoParties,
          "cargoCommodity": cargoCommodity,
        }

        history.push(`${location.pathname}/add`, templateJson)

      } else {
        setValue('carrierCode', carrierCode || '', { shouldDirty: true })
        setValue('ccn', carrierCode + ccn || '', { shouldDirty: true })
        setValue('cargoType', cargoType || '', { shouldDirty: true })
        // setValue('arrivalDate', arrivalDateSeparate || '', { shouldDirty: true })
        // setValue('arrivalTime', arrivalTimeSeparate || '', { shouldDirty: true })
        setValue('firstExpectedPort', firstExpectedPort || '', { shouldDirty: true })
        setValue('arrivalDateAndTimeZone', arrivalDateAndTimeZone || '', { shouldDirty: true })
        setValue('paperRelease', paperRelease || '', { shouldDirty: true })
        setValue('inbondDestinationOfficeCode', inbondDestinationOfficeCode || '', { shouldDirty: true })
        setValue('warehouseCode', warehouseCode || '', { shouldDirty: true })
        setValue('polCity', polCity || '', { shouldDirty: true })
        setValue('polCountryCode', polCountryCode || '', { shouldDirty: true })
        setValue('polStateCode', polStateCode || '', { shouldDirty: true })
        setValue('poaCity', poaCity || '', { shouldDirty: true })
        setValue('poaCountryCode', poaCountryCode || '', { shouldDirty: true })
        setValue('poaStateCode', poaStateCode || '', { shouldDirty: true })
        setValue('adminName', adminName || '', { shouldDirty: true })
        setValue('adminContact', adminContact || '', { shouldDirty: true })
        setValue('specialInstructions', specialInstructions || '', { shouldDirty: true })
        setValue('portOfExit', portOfExit || '', { shouldDirty: true })
        setValue('acquittalNo', acquittalNo || '', { shouldDirty: true })
        setValue('printDate', printDate || '', { shouldDirty: true })
        setValue('printRemarks', printRemarks || '', { shouldDirty: true })
        setValue('messageFunction', messageFunction || '', { shouldDirty: true })
        setValue('version', version || '', { shouldDirty: true })
        setValue('cargoParties', cargoParties || '', { shouldDirty: true })
        setValue('cargoCommodity', cargoCommodity || '', { shouldDirty: true })

        setOnChangeCarrierCode(true);
        setRefreshCarrierCodeAndCCN(true);
        showNotification('success', 'Template applied successfully.')
        trigger()
      }
    } else {
      reset()
    }
  }

  const columns = [
    {
      field: 'id',
      title: 'ID'
    },
    {
      field: 'templateName',
      title: 'TEMPLATE NAME'
    },
    {
      field: 'carrierCode',
      title: 'CARRIER CODE'
    },
    {
      field: 'firstExpectedPort',
      title: 'FIRST EXPECTED PORT'
    },
    {
      field: 'status',
      sortKey: 'status',
      title: translatedTextsObject ? translatedTextsObject.status : getTranslatedText('ACI_HIGHWAY_CARGO', 'status'),
      render: (data) => {
        const status = getStatusMetadata(data.status)

        return (
          <Typography variant='inherit' style={{ color: status.color }}>
            {status.label}
          </Typography>
        )
      }
    },
    {
      field: 'createdDate',
      //sortKey: 'CREATED DATE',
      title: 'CREATED DATE',
      render: (data) =>
        data.createdDate && moment(data.createdDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: 'updatedDate',
      //sortKey: 'UPDATED DATE',
      title: 'UPDATED DATE',
      render: (data) =>
        data.updatedDate && moment(data.updatedDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    isTable ? {
      title: 'Action'
    } : {}
  ]

  const sortOptions = [
    {
      label: translatedTextsObject ? translatedTextsObject.status : getTranslatedText('ACI_HIGHWAY_CARGO', 'status'),
      value: 'status'
    }
  ]

  return (
    <TemplateSelectDialog
      columns={columns}
      defaultSorts={[{ field: 'createdDate', direction: 'ASC' }]}
      fetch={{ url: AciHighwayCargoApiUrls.GET }}
      fetchFilters={
        [
          { field: 'templateFlag', operator: 'equal', value: true },
          { field: 'status', operator: 'equal', value: '1002' },
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]
      }
      onApplyTemplate={handleApplyCargoTemplate}
      onClose={() => setCargoTemplateDialog(false)}
      open={cargoTemplateDialog}
      search={{ field: 'templateName', operator: LIKE }}
      sortOptions={sortOptions}
      title='Cargo template'
      rowActions={rowActions}
      isTable={isTable}
    />
  )
}

export default TemplateSelectDialogWithOptionACIHWYCargo