const AceOcnManifestKeys = {
  TITLE: "title",
  SCAC: "scac",
  VESSEL_NAME: "vesselName",
  VOYAGE_NUMBER: "voyageNumber",
  VESSEL_COUNTRY: "vesselCountry",
  PORT_OF_DISCHARGE: "portOfDischarge",
  E_T_A: "eTA",
  MODE_OF_TRANSPORT: "modeOfTransport",
  PAPERLESS_INBOND_PARTICIPANT: "paperlessInbondParticipant",
  ISSUER_SCAC: "issuerScac",
  PARTY_ID: "partyId",
  SUBMITTED_BY: "submittedBy",
  SUBMITTED_DATE: "submittedDate",
  MODE_OF_SUBMISSION: "modeOfSubmission",
  STATUS: "status",
  MESSAGE_FUN_CODE: "messageFunCode",
  SAVE_FLAG:"saveFlag",

  SAVE_BUTTON: "saveButton",
  EDIT_BUTTON: "editButton",
  VIEW_BUTTON: "viewButton",
  CLONE_BUTTON: "cloneButton",
  DELETE_BUTTON: "deleteButton",
  UPDATE_BUTTON: "updateButton",
  SUBMIT_BUTTON: "submitButton",
  EDIT_SENT_RECORDS: "editSentRecord",
  VIEW_MANIFEST:"viewManifest",

  CREATED_DATE:"createdDate",
  RESPONSE_DATE:"responseDate",
  UPDATED_DATE:"updatedDate",
  CLOSE_BUTTON: "closeButton",
  CREATED_BY_LOGIN_ID: "createdByLoginId",
  Validations: {
    ERR_MSG_ALPHA_NUMERIC: "validations.errMsgAlphaNumeric",
    ERR_MSG_ALPHA_NUMERIC_SPACE: "validations.errMsgAlphaNumericSpace"
  }
}

export default AceOcnManifestKeys
