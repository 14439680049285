const AceOcnVesselMasterKeys = {
  TITLE: "title",
  VESSEL_CODE: "vesselCode",
  VESSEL_NAME: "vesselName",
  VESSEL_OWNER: "vesselOwner",
  VESSEL_COUNTRY: "vesselCountry",
  Validations: {
    ERR_MSG_ALPHA_NUMERIC_SPACE: "validations.errMsgAlphaNumericSpace",
    ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR: "validations.errMsgAlphaNumericSpecChar"
  }
}

export default AceOcnVesselMasterKeys
